import { Slide, Zoom } from "@mui/material";
import type { TransitionProps } from "@mui/material/transitions";
import React from "react";

import { TESTS_TIME_ACCELERATOR } from "~/constants/global.ts";

export const TRANSITION_TIMEOUT = 500 * TESTS_TIME_ACCELERATOR;

export const TransitioZoom = React.forwardRef(function Transition(
	props: TransitionProps & {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>,
) {
	return <Zoom ref={ref} {...props} />;
});

export const TransitionUp = React.forwardRef(function Transition(
	props: TransitionProps & {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>,
) {
	return (
		<Slide direction="up" timeout={TRANSITION_TIMEOUT} ref={ref} {...props} />
	);
});

export const TransitionDown = React.forwardRef(function Transition(
	props: TransitionProps & {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>,
) {
	return (
		<Slide direction="down" timeout={TRANSITION_TIMEOUT} ref={ref} {...props} />
	);
});
